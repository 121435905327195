import { RootStore } from "./rootStore";
import { observable, action } from "mobx";

export default class ModalStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable.shallow modal = {
    open: false,
    body: null,
  };

  @observable.shallow imageModal = {
    open: false,
    imagePath: "",
    caption: "",
    description: "",
    hasPrev: false,
    hasNext: false,
    openNext: () => {},
    openPrev: () => {},
  };

  @action openModal = (content: any) => {
    this.modal.open = true;
    this.modal.body = content;
  };

  @action closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };

  @action openImageModalWithActions = (
    caption: string,
    imageSrc: string,
    description: string = "",
    hasPrev: boolean = false,
    hasNext: boolean = false,
    openNext: () => void,
    openPrev: () => void
  ) => {
    this.imageModal.open = true;
    this.imageModal.imagePath = imageSrc;
    this.imageModal.caption = caption;
    this.imageModal.description = description;
    this.imageModal.openNext = openNext;
    this.imageModal.openPrev = openPrev;
    this.imageModal.hasNext = hasNext;
    this.imageModal.hasPrev = hasPrev;
  };

  @action openImageModal = (caption: string, imageSrc: string) => {
    this.imageModal.open = true;
    this.imageModal.imagePath = imageSrc;
    this.imageModal.caption = caption;
  };

  @action closeImageModal = () => {
    this.imageModal.open = false;
    this.imageModal.imagePath = "";
    this.imageModal.caption = "";
    this.imageModal.description = '';
    this.imageModal.openNext = () => {};
    this.imageModal.openPrev = () => {};
    this.imageModal.hasNext = false;
    this.imageModal.hasPrev = false;
  };
}
