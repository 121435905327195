import React, { useContext } from "react";
import IGalleryImage from "../../app/common/util/imageGallery";
import { RootStoreContext } from "../../app/stores/rootStore";
import { Image } from "semantic-ui-react";

interface IProps {
  images: IGalleryImage[];
}

export const ImageGallery: React.FC<IProps> = ({ images }) => {
  const rootStore = useContext(RootStoreContext);
  const { openImageModalWithActions } = rootStore.modalStore;

  let currentIndex = 0;

  const openImage = (imageIndex: number) => {
    currentIndex = imageIndex;
    openImageModalWithActions(
      images[imageIndex].title,
      images[imageIndex].url,
      images[imageIndex].caption,
      currentIndex > 0,
      currentIndex + 1 < images.length,
      loadNextImage,
      loadPreviousImage
    );
  };

  const loadNextImage = () => {
    if (currentIndex + 1 < images.length) {
      openImage(currentIndex + 1);
    }
  };

  const loadPreviousImage = () => {
    if (currentIndex > 0) {
      openImage(currentIndex - 1);
    }
  };

  const imgs: any[] = [];
  for (let i = 0; i < images.length; i++) {
    imgs.push(
      <Image
        key={i}
        src={images[i].url}
        size="small"
        style={{ cursor: "zoom-in" }}
        onClick={() => openImage(i)}
      />
    );
  }

  return <Image.Group content={imgs}></Image.Group>;
};
