import React, { useState, useEffect } from "react";
import {
    Segment,
    Header,
    Table,
    TableRow,
    TableCell,
    TableHeader,
    Grid,
    TableBody,
} from "semantic-ui-react";
import { ImageGallery } from "../imageGallery/ImageGallery";
import IGalleryImage from "../../app/common/util/imageGallery";
import agent from "../../app/api/agent";
import MediaQuery from "react-responsive"

export const Garage = () => {
    const [images, setImages] = useState<IGalleryImage[] | null>(null);
    const [imgsLoaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!imgsLoaded) {
            setLoaded(true);

            agent.Image.garage().then((imgs) => {
                setImages(imgs);
            });

        }
    }, [imgsLoaded]);

    return (
        <Grid>
            <Grid.Row>
                <Segment style={{ marginLeft: "14px" }}>
                    Seit der Saison 2016/17 bestreiten wir unsere Heimspiele in der{" "}
                    <a
                        href="https://calm-ocean-08173dd03.azurestaticapps.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Cafe & Bar Garage
                  </a>
                  . Hier haben wir uns nach unseren Vorstellungen eine neue Boardanlage mit insgesamt 4 Dartboards
                  konstruiert. Alle Boards verfügen über Computer um das Schreiben zu erleichtern.
                    Zusätzlich können wir die Spiele auf den Boards 1 und 2 per Kamera auf die Leinwände im Lokal übertragen.
                   <br />
                  Wirt Ali und sein Team kümmern sich freundlich um alle gastronomischen Belange.
                  <Header as="h4">Adresse</Header>
                  Rennweg 12-14
                  <br />
                  90489 Nürnberg
                  <br />
                  Direkt an der U-Bahn Haltstelle Rennweg der Linie U2
                </Segment>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column >
                    <Table floated="left" unstackable>
                        <TableHeader>
                            <Table.Row>
                                <TableCell>
                                    <h4>Öffnungszeiten</h4>
                                </TableCell>
                            </Table.Row>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell collapsing>Montag bis Donnerstag</TableCell>
                                <TableCell>16:00 - 02:00</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Freitag</TableCell>
                                <TableCell>16:00 - 03:00</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Samstag</TableCell>
                                <TableCell>14:00 - 03:00</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Sonntag</TableCell>
                                <TableCell>14:00 - 0:00</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Vor einem Feiertag</TableCell>
                                <TableCell>16:00 - 03:00</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid.Column>

                <MediaQuery minWidth={1024} >
                    <Grid.Column>
                        {images !== null && <ImageGallery images={images!} />}
                    </Grid.Column>
                </MediaQuery>
            </Grid.Row>
            <MediaQuery maxWidth={1024} >
                <Grid.Row style={{ paddingLeft: '15px' }}>
                    {images !== null && <ImageGallery images={images!} />}
                </Grid.Row>
            </MediaQuery>
        </Grid >
    );
};
