import { getPointsFromPlacing } from "../common/util/util";
import { ICompetition, ICompetitionParticipant } from "./competition";
import { IParticipant } from "./participant";
import { IUser } from "./user";

export class Competition implements ICompetition {
    creatorUser: IUser;
    creator: string = "";
    date: Date;
    id: number;
    participants: ICompetitionParticipant[];
    constructor(creator: IUser, date: Date) {
        this.creatorUser = creator;
        this.date = date;
        this.id = -1;
        this.participants = [];
    }
}

export class CompetitionParticipantFormValues {
    participant: IParticipant | null = null;
    placing: number;
    points: number;
    bestleg9?: string = undefined;
    bestleg10?: string = undefined;
    bestleg11?: string = undefined;
    bestleg12?: string = undefined;
    bestleg13?: string = undefined;
    bestleg14?: string = undefined;
    bestleg15?: string = undefined;
    bestleg16?: string = undefined;
    bestleg17?: string = undefined;
    bestleg18?: string = undefined;
    bestleg19?: string = undefined;
    oneHundredEighties?: string = undefined;
    highFinishes: string = "";

    constructor(placing: number) {
        this.placing = placing;
        this.points = getPointsFromPlacing(placing);
        // this.participant = { name: '', anonymize: false, club: '', id: -1 };
    }

    static fromCompetitionParticipant = (competitionParticipant: ICompetitionParticipant) => {
        let values = new CompetitionParticipantFormValues(competitionParticipant.placing);

        values.bestleg9 = competitionParticipant.bestleg9 ? competitionParticipant.bestleg9.toString() : undefined;
        values.bestleg10 = competitionParticipant.bestleg10 ? competitionParticipant.bestleg10.toString() : undefined;
        values.bestleg11 = competitionParticipant.bestleg11 ? competitionParticipant.bestleg11.toString() : undefined;
        values.bestleg12 = competitionParticipant.bestleg12 ? competitionParticipant.bestleg12.toString() : undefined;
        values.bestleg13 = competitionParticipant.bestleg13 ? competitionParticipant.bestleg13.toString() : undefined;
        values.bestleg14 = competitionParticipant.bestleg14 ? competitionParticipant.bestleg14.toString() : undefined;
        values.bestleg15 = competitionParticipant.bestleg15 ? competitionParticipant.bestleg15.toString() : undefined;
        values.bestleg16 = competitionParticipant.bestleg16 ? competitionParticipant.bestleg16.toString() : undefined;
        values.bestleg17 = competitionParticipant.bestleg17 ? competitionParticipant.bestleg17.toString() : undefined;
        values.bestleg18 = competitionParticipant.bestleg18 ? competitionParticipant.bestleg18.toString() : undefined;
        values.bestleg19 = competitionParticipant.bestleg19 ? competitionParticipant.bestleg19.toString() : undefined;
        values.highFinishes = competitionParticipant.highFinishes;
        values.oneHundredEighties = competitionParticipant.oneHundredEighties ? competitionParticipant.oneHundredEighties.toString() : undefined;
        values.participant = competitionParticipant.participant;

        return values;
    }

    static toICompetitionParticipant = (participantFormValues: CompetitionParticipantFormValues) => {
        let part: ICompetitionParticipant = {
            participant: participantFormValues.participant!,
            placing: participantFormValues.placing,
            competitionId: -1,
            points: participantFormValues.points,
            highlightCount: 0,
            year: 0,
            bestleg9: participantFormValues.bestleg9 ? parseInt(participantFormValues.bestleg9) : 0,
            bestleg10: participantFormValues.bestleg10 ? parseInt(participantFormValues.bestleg10) : 0,
            bestleg11: participantFormValues.bestleg11 ? parseInt(participantFormValues.bestleg11) : 0,
            bestleg12: participantFormValues.bestleg12 ? parseInt(participantFormValues.bestleg12) : 0,
            bestleg13: participantFormValues.bestleg13 ? parseInt(participantFormValues.bestleg13) : 0,
            bestleg14: participantFormValues.bestleg14 ? parseInt(participantFormValues.bestleg14) : 0,
            bestleg15: participantFormValues.bestleg15 ? parseInt(participantFormValues.bestleg15) : 0,
            bestleg16: participantFormValues.bestleg16 ? parseInt(participantFormValues.bestleg16) : 0,
            bestleg17: participantFormValues.bestleg17 ? parseInt(participantFormValues.bestleg17) : 0,
            bestleg18: participantFormValues.bestleg18 ? parseInt(participantFormValues.bestleg18) : 0,
            bestleg19: participantFormValues.bestleg19 ? parseInt(participantFormValues.bestleg19) : 0,
            oneHundredEighties: participantFormValues.oneHundredEighties ? parseInt(participantFormValues.oneHundredEighties) : 0,
            highFinishes: participantFormValues.highFinishes,
        };

        return part;
    };
}
