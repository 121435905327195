import React from "react";
import { ICompetitionParticipant } from "../../../app/models/competition";
import { observer } from "mobx-react-lite";
import { Table, Popup, Button, Icon } from "semantic-ui-react";

interface IProps {
  participants: ICompetitionParticipant[];
  handleParticipantEdit?: (participant: ICompetitionParticipant) => void;
}

const CompetitionDetailedInfo: React.FC<IProps> = ({ participants, handleParticipantEdit }) => {
  return (
    <Table celled textAlign="center" unstackable >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Platz</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Verein</Table.HeaderCell>
          <Table.HeaderCell>171+</Table.HeaderCell>
          {participants.some((p) => p.bestleg9 > 0) && (
            <Table.HeaderCell>9</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg10 > 0) && (
            <Table.HeaderCell>10</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg11 > 0) && (
            <Table.HeaderCell>11</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg12 > 0) && (
            <Table.HeaderCell>12</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg13 > 0) && (
            <Table.HeaderCell>13</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg14 > 0) && (
            <Table.HeaderCell>14</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg15 > 0) && (
            <Table.HeaderCell>15</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg16 > 0) && (
            <Table.HeaderCell>16</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg17 > 0) && (
            <Table.HeaderCell>17</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg18 > 0) && (
            <Table.HeaderCell>18</Table.HeaderCell>
          )}
          {participants.some((p) => p.bestleg19 > 0) && (
            <Table.HeaderCell>19</Table.HeaderCell>
          )}
          {participants.some((p) => p.highFinishes !== null) && (
            <Table.HeaderCell>High-Finishes</Table.HeaderCell>
          )}
          {handleParticipantEdit != null && (<Table.HeaderCell></Table.HeaderCell>)}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {participants.map((participant) => (
          <Table.Row key={participant.participant.id}>
            <Popup
              content={`entspricht ${participant.points} Punkten`}
              trigger={<Table.Cell>{participant.placing}</Table.Cell>}
            />
            <Table.Cell>{participant.participant.name}</Table.Cell>
            <Table.Cell>{participant.participant.club}</Table.Cell>
            <Table.Cell>{participant.oneHundredEighties > 0 ? participant.oneHundredEighties : '-'}</Table.Cell>
            {participants.some((p) => p.bestleg9 > 0) && (
              <Table.Cell>{participant.bestleg9 > 0 ? participant.bestleg9 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg10 > 0) && (
              <Table.Cell>{participant.bestleg10 > 0 ? participant.bestleg10 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg11 > 0) && (
              <Table.Cell>{participant.bestleg11 > 0 ? participant.bestleg11 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg12 > 0) && (
              <Table.Cell>{participant.bestleg12 > 0 ? participant.bestleg12 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg13 > 0) && (
              <Table.Cell>{participant.bestleg13 > 0 ? participant.bestleg13 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg14 > 0) && (
              <Table.Cell>{participant.bestleg14 > 0 ? participant.bestleg14 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg15 > 0) && (
              <Table.Cell>{participant.bestleg15 > 0 ? participant.bestleg15 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg16 > 0) && (
              <Table.Cell>{participant.bestleg16 > 0 ? participant.bestleg16 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg17 > 0) && (
              <Table.Cell>{participant.bestleg17 > 0 ? participant.bestleg17 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg18 > 0) && (
              <Table.Cell>{participant.bestleg18 > 0 ? participant.bestleg18 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.bestleg19 > 0) && (
              <Table.Cell>{participant.bestleg19 > 0 ? participant.bestleg19 : '-'}</Table.Cell>
            )}
            {participants.some((p) => p.highFinishes !== null) && (
              <Table.Cell>{participant.highFinishes}</Table.Cell>
            )}
            {handleParticipantEdit != null && (
              <Table.Cell>
                <Button positive onClick={(event: any) => { handleParticipantEdit(participant) }}>
                  <Icon name="edit" />
                </Button>
              </Table.Cell>)}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default observer(CompetitionDetailedInfo);
