import React, { useEffect, useState } from 'react'
import { Header, Segment } from 'semantic-ui-react';            
import { ImageGallery } from "../imageGallery/ImageGallery";
import IGalleryImage from "../../app/common/util/imageGallery";
import agent from "../../app/api/agent";

export const Tournament = () => {
    const [images, setImages] = useState<IGalleryImage[] | null>(null);
    const [imgsLoaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!imgsLoaded) {
            setLoaded(true);

            agent.Image.tournament().then((imgs) => {
                setImages(imgs);
            });

        }
    }, [imgsLoaded]);
    
    return (
        <Segment>
            <Header as="h2">Turniere</Header>
            Bereits seit vielen Jahren veranstalten wir erfolgreich Dartturniere. Angefangen mit Stadtmeisterschaften in Nürnberg,
            über bayerische Ranglistenturniere bis zum DDV Ranglistenturnier. Über die Jahre ist unsere Ausstattung massiv gewachsen,
            so dass wir heute über eine 50 Boards Dartanlage verfügen, sowie alles weitere Equipment um ein großes Dartturnier zu veranstalten.
            <br /><br />
            <b>In diesem Jahr finden die Nürnberg Steeldart Open vom 22.-24.11.2024 statt. </b>
            <br />
            <img src="https://finreactigansapistorage.blob.core.windows.net/assets/DDV2024.jpg" alt="Plakat DDV 2024" height="800" />

            <h2><a href="https://ddv.2k-dart-software.de/index.php/de/?option=com_dartliga&controller=showtournamentregistration&layout=showdashboard&filVbKey=1&showtouregKey=37">Hier gehts zur Anmeldung</a></h2>

            <br />
            <h4>Zuschauer sind herzlich Willkommen. Der Eintritt ist frei, für das leibliche Wohl ist gesorgt.</h4>
            <br />

            {images !== null && images.length > 0 &&  <ImageGallery images={images!} />}
        </Segment>
    );
}