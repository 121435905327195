import React from "react";

export const PayPalButton = () => {
  return (
    <div>
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="DQQKP5KW3RT3L" />
        <input
          type="image"
          src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donateCC_LG.gif"         
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Spenden mit dem PayPal-Button"
        />
      </form>
    </div>
  );
};
