import React from "react";
import { Card, Segment, Header, Image } from "semantic-ui-react";
import { PayPalButton } from "./PayPalButton";


export const Sponsors = () => {
  return (
    <Segment>
      <Header as="h2">Unsere Sponsoren</Header>
      Dank zahlreicher Sponsoren konnte sich Finnigan's mit dem Aufstieg der
      Ersten Mannschaft in die Bundesliga einen Vereinsbus anschaffen. <br />
      Dieser ermöglicht es uns die Fahrtkosten bei Auswärtsfahrten zu reduzieren
      und gleichzeitig die Fahrt als Team durchzuführen.
      <br />
      <br />
      <Image src="/assets/sponsors/Bus.jpg" size="huge" />
      <Header as="h3">
        Der DSV Finnigan's Harp bedankt sich bei seinen Sponsoren
      </Header>
      <Card.Group>
        <Card
          href="http://www.wickels.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12 }}>
            Wickels
          </Card.Header>
          <Image
            src="/assets/sponsors/Wickels.jpg"
            size="small"
            centered
            style={{ marginBottom: 12 }}
          />
        </Card>
        <Card
          href="http://dartkiste.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12 }}>
            Jochen's Dartkiste
          </Card.Header>
          <Image
            className="centeredImage"
            src="/assets/sponsors/Dartkiste.png"
            size="small"
            centered
          />
        </Card>
        <Card
          href="http://crivatec.de/site/garage-nbg-new/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12 }}>
            Cafe & Bar Garage
          </Card.Header>
          <Image src="/assets/sponsors/Garage.png" size="small" centered />
        </Card>
        <Card
          href="http://www.happy-tops-dartservice.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12 }}>
            Happy Tops
          </Card.Header>
          <Image src="/assets/sponsors/HappyTops.jpg" size="small" centered />
        </Card>
        <Card
          href="http://www.getraenkeland24.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12 }}>
            Bisloher Getränkeland
          </Card.Header>
          <Image
            src="/assets/sponsors/bislohe.png"
            size="small"
            centered
            style={{ marginBottom: 12 }}
          />
        </Card>
        <Card
          href="http://www.insutrend.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12 }}>
            Insutrend
          </Card.Header>
          <Image src="/assets/sponsors/insutrend.png" size="small" centered />
        </Card>
        <Card
          href="http://www.ftl-nuernberg.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12 }}>
            FTL Logistik
          </Card.Header>
          <Image
            src="/assets/sponsors/Ftl.png"
            size="small"
            centered
            style={{ marginBottom: 12 }}
          />
        </Card>
        <Card>
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12, color: '#4183c4' }}>
            Möchten Sie uns unterstützen und ebenfalls Sponsor werden?
          </Card.Header>
          <Card.Description style={{ textAlign: "center" }}>
            Schreiben Sie uns eine{" "}
            <a href="mailto:info@finnigans-harp.de">Nachricht</a>.
          </Card.Description>
        </Card>
        <Card>
          <Card.Header as="h3" textAlign="center" style={{ marginTop: 12, color: '#4183c4' }}>
            Alternativ können Sie uns auch mit einer Spende per Paypal
            unterstützen
          </Card.Header>
          <Card.Description style={{ display: "block", marginLeft: 'auto', marginRight: 'auto' }}>
           <PayPalButton />
          </Card.Description>
        </Card>
      </Card.Group>
    </Segment>
  );
};
