import React from "react";
import { Header, Image, Container, Segment } from "semantic-ui-react";
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";

const HomePage = () => {
    return (
        <Container>
            <Segment>
                <MediaQuery maxWidth={1024} >
                    <Header as="h3" textAlign="center"
                        className="homeHeader">
                        <Image
                            src="/assets/Home/harfe_transparent.png"
                            size="small"
                            floated="left" />DSV Finnigans Harp Nürnberg e.V.</Header>
                    <br />
                </MediaQuery>
                <MediaQuery minWidth={1024} >
                    <Header as="h1" textAlign="center">DSV Finnigans Harp Nürnberg e.V.</Header>
                    <Image src="/assets/Home/harfe_transparent.png"
                        floated="left"
                        size="small"
                    />
                </MediaQuery>

                Herzlich willkommen beim Dartverein DSV Finnigan's Harp Nürnberg e. V., auch bekannt als Finnigan's Harp!
                Seit unserer Gründung im Jahr 1984 haben wir uns zu einer festen Größe in der Dartwelt entwickelt.
                Unser Verein erhielt seinen Namen aufgrund des damaligen Spielortes, der irischen Gaststätte "Finnigan's Harp" in der Nürnberger Innenstadt.
                In den Anfangsjahren fanden unsere Dartmatches in der gemütlichen Atmosphäre von Finnigan's Harp statt.
                Später wechselten alle Mannschaften für viele Jahre in den Heinrich VIII, Burgerstraße 42, wo wir unsere Leidenschaft für das Dartspiel weiter ausleben konnten.
                Zusätzlich hatten wir das Vergnügen, in der Sportgaststätte beim DJK Falke in Nürnberg unsere Dartsessions zu veranstalten.
                Inzwischen ist unser Zuhause, die <Link to="/garage">Cafe & Bar Garage</Link>, die sich direkt an der Ubahn-Station Rennweg befindet.
                Hier treffen wir uns regelmäßig, um unsere Leidenschaft für den Dartsport zu teilen und spannende Wettkämpfe auszutragen.
                Die Cafe Bar Garage bietet eine einladende Atmosphäre und ist der ideale Ort, um gemeinsam mit Gleichgesinnten das Dartspiel zu genießen.
                <br /><br />
                Unser Verein hat eine beeindruckende Entwicklung durchgemacht und ist heute ein fester Bestandteil der Dart-Community in Nürnberg.
                Wir haben uns kontinuierlich weiterentwickelt, hart trainiert und sind zu einer starken Einheit zusammengewachsen.
                Unsere Spielerinnen und Spieler sind mit Leidenschaft, Geschick und Hingabe dem Dartspiel verbunden und
                repräsentieren den DSV Finnigan's Harp Nürnberg e. V. mit Stolz.
                <br /><br />
                Unsere erste Mannschaft spielt in der anspruchsvollen 2. Bundesliga und misst sich mit anderen talentierten Teams.
                Durch harte Arbeit und Teamgeist haben wir uns diesen Platz in der Liga erkämpft und sind bereit, unser Können zu demonstrieren.
                Für die kommende Saison 2023/2024 haben wir fünf Vollmannschaften gemeldet, die in verschiedenen Ligen und Wettbewerben antreten werden.
                Egal ob erfahrene Spieler oder Anfänger, bei uns findet jeder eine passende Spielgelegenheit, um sich weiterzuentwickeln und Spaß am Dartsport zu haben.
                <br /><br />
                Ein wichtiger Bestandteil unserer Vereinsgeschichte sind unsere zahlreichen Erfolge. Als mehrfacher Bayernligameister haben wir uns in
                den Jahren 2023, 2014, 2008, 2007 und 1998 den begehrten Titel gesichert. Darüber hinaus sind wir Rekord-MOFDV-Pokal Sieger
                (in den Jahren 2023, 2018, 2015, 2014, 2010, 2009, 2001, 1999 und 1997). Auch den bayerischen 8er Cup konnten wir in den Jahren
                2015, 2002, 2001 und 1996 zu uns nach Franken holen.
                Wir sind bestrebt, unsere Erfolgsgeschichte fortzusetzen und unseren Verein auf höchstem Niveau zu repräsentieren.
                Wir laden dich herzlich ein, Teil der Finnigan's Harp-Familie zu werden und gemeinsam die Faszination des Dartsports zu erleben.

                <h3>Finnigan's Harp - Wo Legenden geboren werden, und Pfeile fliegen!</h3>              
            </Segment>
        </Container>
    );
};

export default HomePage;
