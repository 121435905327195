import React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { TextInput } from "../../app/common/form/TextInput";
import { Form, Button, Header } from "semantic-ui-react";
import { combineValidators, isRequired } from "revalidate";
import { CheckBoxInput } from "../../app/common/form/CheckBoxInput";
import { IParticipant } from "../../app/models/participant";

const validate = combineValidators({
  name: isRequired("name"),
  club: isRequired("club"),
});

interface IProps {
  handleSubmit: (values: IParticipant) => void;
  name: string;
}

export const NewParticipantForm: React.FC<IProps> = ({ handleSubmit, name }) => {
  return (
    <FinalForm
      onSubmit={handleSubmit}
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Form onSubmit={handleSubmit} error>
          <Header
            as="h2"
            content="Neuen Teilnehmer anlegen"
            color="teal"
            textAlign="center"
          />
          <Field name="name" component={TextInput} placeholder="Name" initialValue={name} />
          <Field name="club" component={TextInput} placeholder="Verein" initialValue='o.V.' />
          <Field
            name="anonymize"
            component={CheckBoxInput}
            label="Daten anonymisiert"
            falseLabel="Daten nicht anonymisiert"
          />

          <Button
            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
            loading={submitting}
            color="teal"
            content="Hinzufügen"
            fluid
          />
        </Form>
      )}
    />
  );
};
