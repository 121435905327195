import React from "react";
import { Card, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { IRankingValue } from "../../../app/models/ranking";

export const MobileRankingListItem: React.FC<{
    rankingValue: IRankingValue;
}> = ({ rankingValue }) => {
    return (    
        <Card key={rankingValue.id} >
            <Card.Content>
                <Card.Header as={Link} to={`/participants/${rankingValue.id}`} className="homeHeader">{rankingValue.name}</Card.Header>
                <Card.Meta>{rankingValue.club}</Card.Meta>
                <Card.Description>
                    <Grid>
                        <Grid.Row columns={2} style={{ paddingTop: 12, paddingBottom: 0 }}>
                            <Grid.Column className="boldGridColumn">Platz</Grid.Column>
                            <Grid.Column>{rankingValue.placing}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2} style={{ paddingTop: 8, paddingBottom: 0 }}>
                            <Grid.Column className="boldGridColumn">Teilnahmen</Grid.Column>
                            <Grid.Column>{rankingValue.participations}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2} style={{ paddingTop: 8, paddingBottom: 0  }}>
                            <Grid.Column className="boldGridColumn">Punkteschnitt</Grid.Column>
                            <Grid.Column>{rankingValue.average}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2} style={{ paddingTop: 8, paddingBottom: 8  }}>
                            <Grid.Column className="boldGridColumn">Punkte</Grid.Column>
                            <Grid.Column>{rankingValue.points}</Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Description>
            </Card.Content>
        </ Card>
    );
};
