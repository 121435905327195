import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { ITeam } from "../models/team";

export default class TeamStore{
    rootStore: RootStore;
    constructor (rootStore: RootStore){
      this.rootStore = rootStore;
    }

    @observable teamCache = new Map();
    @observable team: ITeam | null = null;
    @observable loading = false;

    @action getTeam = async (id: number) => {
        let cachedTeam = this.teamCache.get(id);
        if (cachedTeam){
            this.team = cachedTeam;
        } else{
            await this.loadTeam(id);
        }
    }

    @action loadTeam = async (id: number) => {
        this.loading = true;

        try {
            var team = await agent.Team.details(id);
            runInAction(() => {
                this.loading = false;
                this.teamCache.set(id, team);
                this.team = team;
            })
        } catch (error) {
            runInAction(() => {
                this.loading = false;
            })

            toast.error("Beim Laden des Teams ist ein Fehler aufgetreten.");            
        }
    }
}