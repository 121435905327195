import React, { useEffect, useState, Fragment, useContext } from "react";
import { IParticipantDetail } from "../../app/models/participant";
import { Tab } from "semantic-ui-react";
import { ParticipantHighlights } from "./ParticipantHighlights";
import { ParticipantCompetitions } from "./ParticipantCompetitions";
import { RootStoreContext } from "../../app/stores/rootStore";
import { ParticipantEdit } from "./ParticipantEdit";
import MediaQuery from 'react-responsive'

interface IProps {
    participantDetail: IParticipantDetail;
}

export const ParticipantDetails: React.FC<IProps> = ({ participantDetail }) => {
    const rootStore = useContext(RootStoreContext);
    const { isLoggedIn, user } = rootStore.userStore;

    const [panes, setPanes] = useState<any[]>([]);

    useEffect(() => {
        let temp: any[] = [];

        participantDetail.competitions.map((c) =>
            temp.push({
                menuItem: "Teilnahmen " + c.year.toString(),
                render: () => <ParticipantCompetitions competitions={c} />,
            })
        );

        participantDetail.highlights.map((h) =>
            temp.push({
                menuItem: "Highlights " + h.year.toString(),
                render: () => <ParticipantHighlights highlights={h} />,
            })
        );

        isLoggedIn && user && (
            temp.push({
                menuItem: "Bearbeiten",
                render: () => <ParticipantEdit participantDetail={participantDetail} />,
            })
        )

        setPanes(temp);
    }, [participantDetail, isLoggedIn, user]);

    return (
        <Fragment>
            <MediaQuery minWidth={1024}>
                <Tab
                    panes={panes}
                    menu={{ fluid: true, vertical: true }}
                    menuPosition="right"
                />
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <Tab
                    panes={panes}
                    menu={{ fluid: true, vertical: false, stackable: true  }}
                />
            </MediaQuery>

        </Fragment>
    );
};
