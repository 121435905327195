import React from "react";
import { ITeamMember } from "../../app/models/team";
import { Image, Card } from "semantic-ui-react";

const TeamMemberListItem: React.FC<{ teamMember: ITeamMember }> = ({
  teamMember,
}) => {
    return (
        <Card style={{ minWidth: 115 }}>
      <Card.Content>
        <Image circular size="small" 
        src={teamMember.imagePath} 
        style={{ marginBottom: 10 }} 
        onError={(i : any) => i.target.src='placeholder.png'} />
        <Card.Header>{teamMember.fullName}</Card.Header>
        <Card.Description>{teamMember.number}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default TeamMemberListItem;
