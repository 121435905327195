import React, { useContext, useState, useEffect } from "react";
import { ITeam } from "../../app/models/team";
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Image,
} from "semantic-ui-react";
import MediaQuery from "react-responsive"
import { RootStoreContext } from "../../app/stores/rootStore";

export const TeamHeader: React.FC<{ team: ITeam }> = ({ team }) => {
    const context = useContext(RootStoreContext);
    const { openImageModal } = context.modalStore;

    const handleImageClick = (caption: string, imagePath: string) => {
        if (!imageError) {
            openImageModal(caption, imagePath);
        }
    };

    useEffect(() => {
        setImageError(false);
    }, [team])

    const [imageError, setImageError] = useState(false);

    return (
        <Table unstackable >
            <TableBody>

                <MediaQuery maxWidth={1024}>
                    <TableRow>
                        <TableCell colSpan='2'>
                            <Image
                                fluid
                                rounded
                                floated="left"                                
                                src={team.imagePath}
                                onError={(i: any) => {
                                    i.target.src = "";
                                    setImageError(true);
                                }}
                                onClick={() => handleImageClick(team.name, team.imagePath)}
                            />
                        </TableCell>
                    </TableRow>
                </MediaQuery>
                <TableRow>
                    <MediaQuery minWidth={1024}>
                        <TableCell collapsing rowSpan="4" style={{ cursor: 'zoom-in' }}>
                            <Image
                                rounded
                                floated="left"
                                size="medium"
                                src={team.imagePath}
                                onError={(i: any) => {
                                    i.target.src = "placeholder.png";
                                    setImageError(true);
                                }}
                                onClick={() => handleImageClick(team.name, team.imagePath)}
                            />
                        </TableCell>
                    </MediaQuery>
                    <TableCell>Kapitän</TableCell>
                    <TableCell>{team.captain}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Liga</TableCell>
                    <TableCell>{team.league}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Heimspieltag</TableCell>
                    <TableCell>{team.homeGameDay}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colspan="2"><a href={team.tableUrl} target="_blank" rel="norefferer">Zur Tabelle</a></TableCell>                       
                </TableRow>
            </TableBody>
        </Table >
    );
};
