import React from "react";
import { ICompetition } from "../../../app/models/competition";
import { observer } from "mobx-react-lite";
import { Grid, GridRow, Icon, GridColumn, Popup } from "semantic-ui-react";
import { format, parseISO } from "date-fns";

interface IProps {
  competition: ICompetition;
}

const CompetitionDetailedHeader: React.FC<IProps> = ({ competition }) => {
  return (
    <Grid>
      <GridRow>
              <Icon name="calendar" size="huge" style={{ verticalAlign: 'middle', marginLeft: 15 }} />
              <h2 style={{ marginTop: 15 }} >Competition vom {format(parseISO(competition.date.toString()), "dd.MM.yyyy")}</h2>
       
      </GridRow>
      <GridRow>
        <GridColumn width={8}>
            <Popup
              content="Teilnehmer"
              key={competition.id}
              trigger={
                <Icon
                  name="address book outline"
                  size="large"
                  style={{ margin: 8 }}
                />
              }
            />{" "}
            {competition.participants.length}
          </GridColumn>
          <GridColumn width={8}>
          <Popup
            content="Highlights"
            key={competition.id}
            trigger={
              <Icon name="chart line" size="large" style={{ margin: 8 }} />
            }
          />{" "}
          {competition.participants.reduce((highlightCount, part) => highlightCount + part.highlightCount, 0)}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default observer(CompetitionDetailedHeader);
