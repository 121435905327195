import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed } from "mobx";
import { ICompetitionInfo, ICompetition } from "../models/competition";
import agent from "../api/agent";
import { IRanking } from "../models/ranking";
import { toast } from "react-toastify";
import { FilterType } from "../models/enums";
import { IEternalRanking, IHighlightRanking } from "../models/participant";
import { Competition } from "../models/competitionForm";
import { history } from "../..";

const LIMIT = 60;

export default class CompetitionStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        // reaction(
        //   () => this.predicate.keys(),
        //   () => {
        //     this.page = 0;
        //     this.competitions = null;
        //     this.loadCompetitions();
        //   }
        // );
    }

    @observable competitionCache = new Map();

    @observable availableData: number[] = [];
    @observable competitionCount: number = 0;
    @observable competition: ICompetition | null = null;
    @observable loadingInitial = false;
    @observable loading = false;
    @observable page = 0;
    @observable predicate = new Map();
    @observable filterType = FilterType.Ranking;

    @observable ranking: IRanking | null = null;
    @observable highlights: IHighlightRanking[] | null = null;
    @observable competitions: ICompetitionInfo[] | null = null;
    @observable eternalRanking: IEternalRanking[] | null = null;
    @observable activeTabIndex = 0;

    @computed get axiosParams() {
        const params = new URLSearchParams();

        params.append("limit", LIMIT.toString());
        params.append("offset", `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            params.append(key, value);
        });

        if (!params.has("year")) {
            params.append("year", "2020");
        }

        return params;
    }

    @action loadCompetitions = async () => {
        this.loading = true;

        let key = FilterType.Details + "_" + this.predicate.get("year");
        let cachedEnvelope = this.competitionCache.get(key);
        if (cachedEnvelope) {
            this.loading = false;
            this.competitions = cachedEnvelope.competitions;
            this.competitionCount = cachedEnvelope.competitionCount;
        } else {
            try {
                const competitionsEnvelope = await agent.Competition.list(
                    this.axiosParams
                );
                const { competitions, competitionCount } = competitionsEnvelope;
                runInAction("loading competition", () => {
                    this.competitionCache.set(key, competitionsEnvelope);
                    this.competitionCount = competitionCount;
                    this.competitions = competitions;
                    this.loading = false;
                });
            } catch (error) {
                console.log(error);
                runInAction("load competitions error", () => {
                    this.loading = false;
                });
            }
        }
    };

    @action loadCompetition = async (id: string) => {
        this.loading = false;

        try {
            const competition = await agent.Competition.details(id);
            runInAction("loading competition", () => {
                this.competition = competition;
            });
        } catch (error) {
            console.log(error);
            runInAction("load competition error", () => {
                this.loading = false;
            });
        }
    };

    @action setPage = (page: number) => {
        this.page = page;
    };

    @action setPredicate = (predicate: string, value: string | Date) => {
        this.predicate.clear();
        if (predicate !== "all") {
            this.predicate.set(predicate, value);
        }
    };

    @action loadRanking = async (year: string) => {
        this.loading = true;
        let key = FilterType.Ranking + "_" + year;
        let cachedRanking = this.competitionCache.get(key);
        if (cachedRanking) {
            this.loading = false;
            this.ranking = cachedRanking;
        } else {
            try {
                let ranking = await agent.Competition.ranking(year);
                runInAction(() => {
                    this.loading = false;
                    this.competitionCache.set(key, ranking);
                    this.ranking = ranking;
                });
            } catch (error) {
                runInAction(() => (this.loading = false));
                toast.error("Fehler beim Laden der Tabelle.");
                console.log(error);
            }
        }
    };

    @action loadAvailableData = async () => {
        this.loadingInitial = true;
        try {
            let availableData = await agent.Competition.availableData();

            let yearsGreater2021 = availableData.filter(c => c > 2021).length;
            availableData.splice(yearsGreater2021, 0, 20202021);

            runInAction(() => {
                this.availableData = availableData;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => (this.loadingInitial = false));
            toast.error("Fehler beim Bereitstellen der Daten.");
            console.log(error);
        }
    };

    @action loadHighlights = async (year: string) => {
        this.loading = true;
        let key = FilterType.Highlights + "_" + year;
        let cachedHighlights = this.competitionCache.get(key);
        if (cachedHighlights) {
            this.loading = false;
            this.highlights = cachedHighlights;
        } else {
            try {
                let highlights = await agent.Competition.highlights(year);
                runInAction(() => {
                    this.loading = false;
                    this.competitionCache.set(key, highlights);
                    this.highlights = highlights;
                });
            } catch (error) {
                runInAction(() => (this.loading = false));
                toast.error("Fehler beim Laden der Tabelle.");
                console.log(error);
            }
        }
    };

    @action setActiveTab = (activeIndex: any) => {
        this.activeTabIndex = activeIndex;
    };

    @action createCompetition = async (competition: Competition) => {
        this.loading = true;
        try {
            await agent.Competition.create(competition);
            runInAction(() => {
                this.loading = false;
                this.competitionCache.delete(FilterType.Ranking + "_" + competition.date.getFullYear())
                history.push("/competitions");
            });
        } catch (error) {
            runInAction(() => (this.loading = false));
            toast.error("Fehler beim Speichern der Competition.");
            console.log(error);
        }
    };

    @action loadEternalRanking = async () => {
        this.loading = true;
        let key = "eternal";
        let cachedEternal = this.competitionCache.get(key);
        if (cachedEternal) {
            this.loading = false;
            this.eternalRanking = cachedEternal;
        } else {
            try {
                let eternal = await agent.Competition.eternalranking();
                runInAction(() => {
                    this.loading = false;
                    this.competitionCache.set(key, eternal);
                    this.eternalRanking = eternal;
                });
            } catch (error) {
                runInAction(() => (this.loading = false));
                toast.error("Fehler beim Laden der ewigen Tabelle.");
                console.log(error);
            }
        }
    };
}
