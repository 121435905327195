import React, { useEffect, useState } from 'react'
import { Header, Segment } from 'semantic-ui-react';            
import { ImageGallery } from "../imageGallery/ImageGallery";
import IGalleryImage from "../../app/common/util/imageGallery";
import agent from "../../app/api/agent";

export const Event = () => {
    const [images, setImages] = useState<IGalleryImage[] | null>(null);
    const [imgsLoaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!imgsLoaded) {
            setLoaded(true);

            agent.Image.exhibition().then((imgs) => {
                setImages(imgs);
            });

        }
    }, [imgsLoaded]);
    
    return (
        <Segment>
            <Header as="h2">Messen und Firmenevents</Header>
            2019 wurden wir von der AFAG angesprochen, ob wir Interesse haben den Dartsport und unseren Verein im Bereich 
            der Men's Corner zu präsentieren. Das Ergebnis war ein voller Erfolg. Pausenlos Besucher am Stand, von denen 
            heute einige Mitglied bei Finnigan's Harp und anderen Dartvereinen der Region geworden sind. Auch zukünftig
            werden wir auf Messen vertreten sein und den Dartsport präsentieren.
            <br />           
            Weiterhin arbeiten wir zusammen mit <a href="https://www.mazani.de/">Mazani Die Eventgestalter</a> um den Dartsport
            auf Firmenveranstaltungen, Mitarbeiterfesten, Teambuilding Events und anderen Anlässen zu präsentieren und für Spaß und Unterhaltung zu sorgen.            
            <br/><br/>
            {images !== null && <ImageGallery images={images!} />}
        </Segment>
    );
}