import { RouteComponentProps } from "react-router-dom";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import React from "react";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import CompetitionDetailedHeader from "./CompetitionDetailedHeader";
import CompetitionDetailedInfo from "./CompetitionDetailedInfo";

interface DetailParams {
    id: string;
}

const CompetitionDetails: React.FC<RouteComponentProps<DetailParams>> = ({
    match,
}) => {
    const rootStore = useContext(RootStoreContext);
    const { competition, loadCompetition, loading } = rootStore.competitionStore;

    useEffect(() => {
        loadCompetition(match.params.id);
    }, [loadCompetition, match.params.id]);

    if (loading || competition === null)
        return <LoadingComponent content="Lade Competition..." />;

    return (
        <Grid>
            <Grid.Row>
                <CompetitionDetailedHeader competition={competition!} />
            </Grid.Row>
            <Grid.Row style={{ margin: 8 }}>
                <CompetitionDetailedInfo participants={competition!.participants} />
            </Grid.Row>
        </Grid>
    );
};

export default observer(CompetitionDetails);
