import React, { useContext } from "react";
import { Tab, Form, Button } from "semantic-ui-react";
import { IParticipantDetail } from "../../app/models/participant";
import { RootStoreContext } from "../../app/stores/rootStore";
import { combineValidators, isRequired } from "revalidate";
import { Form as FinalForm, Field } from "react-final-form";
import { TextInput } from "../../app/common/form/TextInput";
import { CheckBoxInput } from "../../app/common/form/CheckBoxInput";

const validate = combineValidators({
  name: isRequired("name"),
  club: isRequired("club"),
});

export const ParticipantEdit: React.FC<{ participantDetail: IParticipantDetail }> = ({
    participantDetail,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    updateParticipant,
  } = rootStore.participantStore;

  return <Tab.Pane>
      <FinalForm
      onSubmit={updateParticipant}
      validate={validate}
      initialValues={participantDetail!}
      render={({ handleSubmit, invalid, pristine, submitting }) => (
        <Form onSubmit={handleSubmit} error>
          <Field
            name='name'
            component={TextInput}
            placeholder='Name (Nachname, Vorname)'
            value={participantDetail!.name}
          />
          <Field
            name='club'
            component={TextInput}
            placeholder='Verein oder o.V.'
            value={participantDetail!.club}
          />
          <Field
            name='anonymize'
            component={CheckBoxInput}
            type='checkbox'
            checked={participantDetail!.anonymize}
            label='Daten anonymisiert'
            falseLabel='Daten nicht anonymisiert'
          />
          <Button 
            loading={submitting}
            floated='right'
            disabled={invalid || pristine}
            positive
            content='Speichern'
          />
        </Form>
      )}
    />
  </Tab.Pane>;
};
