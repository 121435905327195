import React, { Fragment, useContext, useEffect } from "react";
import { Table, Tab, List, Label } from "semantic-ui-react";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import MediaQuery from "react-responsive"
import { MobileRankingListItem } from "./MobileRankingListItem";

interface IProps {
    year: number;
}

const CompetitionRanking: React.FC<IProps> = ({ year }) => {
    const rootStore = useContext(RootStoreContext);
    const { ranking, loading, loadRanking } = rootStore.competitionStore;

    useEffect(() => {
        loadRanking(year.toString());
    }, [loadRanking, year])

    return (
        <Tab.Pane loading={loading}>
            {ranking && (
                <Fragment>
                    <Label.Group size='medium' color="blue">
                        <Label>
                            {`Stand nach der ${ranking.competitionCount}. Competition am`}
                            <Label.Detail>{format(parseISO(ranking.lastCompetitionDate.toString()), "dd.MM.yyyy")}</Label.Detail>
                        </Label>
                        <Label>
                            Teilnehmerschnitt
                            <Label.Detail>{ranking.participantAverage.toFixed(2)}</Label.Detail>
                        </Label>
                        {ranking.jackpot > 0 && (
                            <Label>
                                Aktueller Jackpot
                                <Label.Detail>{ranking.jackpot.toFixed(2)} €</Label.Detail>
                            </Label>
                        )}
                    </Label.Group>
                    <MediaQuery maxWidth={1024}>
                        <List divided relaxed>
                            {ranking && ranking.values.map((c) => (
                                <MobileRankingListItem key={c.id} rankingValue={c} />
                            ))}
                        </List>

                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                        <Table celled textAlign="center">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Rang</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Verein</Table.HeaderCell>
                                    <Table.HeaderCell>TN</Table.HeaderCell>
                                    <Table.HeaderCell>Ø</Table.HeaderCell>
                                    <Table.HeaderCell>Punkte</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {ranking.values.map((value) => (
                                    <Table.Row key={value.id}>
                                        <Table.Cell>{value.placing}</Table.Cell>
                                        <Table.Cell>
                                            <Link to={`/participants/${value.id}`}>{value.name}</Link>
                                        </Table.Cell>
                                        <Table.Cell>{value.club}</Table.Cell>
                                        <Table.Cell>{value.participations}</Table.Cell>
                                        <Table.Cell>{value.average.toFixed(2)}</Table.Cell>
                                        <Table.Cell>{value.points}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </MediaQuery>
                </Fragment>
            )}
        </Tab.Pane>
    );
};

export default observer(CompetitionRanking)