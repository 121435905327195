import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IParticipantDetail, IParticipant, IParticipantEnvelope } from "../models/participant";

export default class ParticipantStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable participantDetail: IParticipantDetail | null = null;
  @observable selectedTabIndex: number = 0;
  @observable loadingParticipant = false;
  @observable submittingParticipant = false;
  @observable participantList : IParticipantEnvelope | null = null;

  @action setSelectedTab = (tabIndex: number) => {
    this.selectedTabIndex = tabIndex;
  };

  
  @action addNewParticipant = (participant: IParticipant ) => {
    this.participantList!.participants.push(participant);
  };

  @action loadParticipants = async () => {
    try {
      const params = new URLSearchParams();

      params.append("limit", '1000');
      params.append("offset", '0');

      var participants = await agent.Participant.list(params);
      runInAction(() => {
        this.participantList = participants;
      });
    } catch (error) {
      toast.error("Beim Laden der Teilnehmer ist ein Fehler aufgetreten.");
      console.log(error);
    }
  };

  @action loadParticipantDetails = async (id: string) => {
    this.loadingParticipant = true;
    this.participantDetail = null;
    try {
      var part = await agent.Participant.details(id);
      runInAction(() => {
        this.loadingParticipant = false;
        this.participantDetail = part;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingParticipant = false;
        this.participantDetail = null;
      });

      toast.error("Beim Laden des Teilnehmers ist ein Fehler aufgetreten.");
      console.log(error);
    }
  };

  @action updateParticipant = async (participant: Partial<IParticipant>) => {
    this.submittingParticipant= true;
    try {
      await agent.Participant.edit(participant);  
      
      runInAction(() => {
        this.submittingParticipant = false;

        this.participantDetail = {...this.participantDetail!, ...participant};
      });
    } catch (error) {
      runInAction(() => {
        this.submittingParticipant = false;
      });

      toast.error("Beim Aktualisieren des Teilnehmers ist ein Fehler aufgetreten.");
      console.log(error);
    }
  }
}
