import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { ISingleLeagueEnvelope } from "../models/singleleague";

export default class SingleLeagueStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable leagueEnvelope: ISingleLeagueEnvelope | null = null;
    @observable loading = false;

    @action getEnvelope = async () => {       
        if (this.leagueEnvelope == null) {            
            await this.loadEnvelope();
        }

        return this.leagueEnvelope;
    }

    @action loadEnvelope = async () => {
        this.loading = true;

        try {
            const params = new URLSearchParams();

            params.append("limit", '100');
            params.append("offset", '0');

            var envelope = await agent.SingleLeague.list(params);
            runInAction(() => {
                this.loading = false;
                this.leagueEnvelope = envelope;
            })
        } catch (error) {
            runInAction(() => {
                this.loading = false;
            })

            toast.error("Beim Laden der Einzelligen ist ein Fehler aufgetreten.");
        }
    }
}