import React, { useContext, useEffect, useState } from 'react'
import { Header, Segment, Table } from 'semantic-ui-react';
import { ISingleLeagueEnvelope } from '../../app/models/singleleague';
import { RootStoreContext } from '../../app/stores/rootStore';


export const SingleLeague = () => {
    const context = useContext(RootStoreContext);
    const { getEnvelope, loading, leagueEnvelope } = context.singleleagueStore;

    const [leagues, setLeagues] = useState<ISingleLeagueEnvelope | null>(null);

    useEffect(() => {
        getEnvelope();
    }, [getEnvelope]);

    useEffect(() => {
        let env = leagueEnvelope;

        setLeagues(env);
    }, [leagueEnvelope]);


    return (
        <Segment loading={loading}>
            <Header as="h2">Einzel Liga</Header>
            Unsere Liga bietet die perfekte Chance, dein Darts-Spiel zu verbessern!
            Mit 9 Spielern pro Liga und 8 Spieltagen über 16 Wochen sorgt unser Format für intensives Training:
            Jedes Leg zählt, da die Rangliste auf der Leg-Differenz basiert.<br /><br />

            Spielort ist die Café Bar Garage, ausgestattet mit 2K-Software für ein professionelles Darts-Erlebnis.
            In der Premier League, Challenger League und Rookie League werden jeweils 12 Legs (501 D.O.) gespielt.
            Die Ligen werden nach Spielstärke gebildet, und nach jeder Saison steigen die besten zwei Spieler auf, während die letzten zwei absteigen.<br /><br />

            Das Startgeld von 25 € sichert dir die Teilnahme, und die besten Spieler erhalten Preise (1. Platz: 75 €, 2. Platz: 60 €, 3. Platz: 50 €).<br /><br />

            Melde dich rechtzeitig an, um deinen Platz zu sichern!<br /><br />

            {leagues && (
                <Table celled textAlign="center">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Liga</Table.HeaderCell>
                            <Table.HeaderCell>Saison</Table.HeaderCell>
                            <Table.HeaderCell>Link</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {leagues.leagues.map((value) => (
                            <Table.Row key={value.id}>
                                <Table.Cell>{value.name}</Table.Cell>
                                <Table.Cell>{value.season}</Table.Cell>
                                <Table.Cell>
                                    <a href={value.link} target="_blank" rel="noopener noreferrer" >
                                        zur 2K Software</a>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
        </Segment>
    );
}