import React, { useContext } from "react";
import { Modal, Image, Button, Icon, Container } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";

const ImageModalContainer = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    imageModal: {
      open,
      imagePath,
      caption,
      description,
      hasNext,
      hasPrev,
      openNext,
      openPrev,
    },
    closeImageModal,
  } = rootStore.modalStore;

  return (
    <Modal open={open} onClose={closeImageModal} size="small" closeIcon>
      <Modal.Header>{caption}</Modal.Header>
      <Modal.Content>
        <Image size="huge" src={imagePath} rounded />
      </Modal.Content>
      {description !== "" && (
        <Modal.Description><Container style={{ marginBottom: 21, paddingLeft: 21}} > {description}
          </Container></Modal.Description>
      )}
      <Modal.Actions>
        {hasPrev && (
          <Button
            color="red"
            floated="left"
            style={{ marginBottom: 14 }}
            onClick={openPrev}
          >
            <Icon name="arrow left" />Vorheriges
          </Button>
        )}
        {hasNext && (
          <Button
            color="green"
            floated="right"
            onClick={openNext}
            style={{ marginBottom: 14 }}
          >
            Nächstes<Icon name="arrow right" />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default observer(ImageModalContainer);
