import React, { useContext, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import {
  Segment,
  Dimmer,
  Loader,
  Header,
  Icon,
} from "semantic-ui-react";
import { ParticipantDetails } from "./ParticipantDetails";

interface DetailParams {
  id: string;
}

const ParticipantPage: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    participantDetail,
    loadParticipantDetails,
    loadingParticipant,
  } = rootStore.participantStore;

  useEffect(() => {
    loadParticipantDetails(match.params.id);
  }, [loadParticipantDetails, match.params.id]);

  return (
    <Segment.Group style={{ minHeight: 170 }}>
      <Dimmer active={loadingParticipant} inverted>
        <Loader>Lade Teilnehmerdaten...</Loader>
      </Dimmer>
      {participantDetail && (
        <Fragment>
          <Segment>
            <Header as="h1">{participantDetail!.name} </Header>
            <Header as="h3">
              <Icon name="beer" />
              <Header.Content> {participantDetail!.club}</Header.Content>
            </Header>
          </Segment>
          <Segment>
            <ParticipantDetails participantDetail={participantDetail!} />
          </Segment>
        </Fragment>
      )}
    </Segment.Group>
  );
};

export default observer(ParticipantPage);
