import React, { Fragment, useContext, useEffect } from "react";
import { Container } from "semantic-ui-react";
import NavBar from "../../features/nav/NavBar";
import {
    Route,
    withRouter,
    RouteComponentProps,
    Switch,
} from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import { observer } from "mobx-react-lite";
import NotFound from "./NotFound";
import { ToastContainer } from "react-toastify";
import { RootStoreContext } from "../stores/rootStore";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import PrivateRoute from "./PrivateRoute";
import CompetitionDashboard from "../../features/competition/Dashboard/CompetitionDashboard";
import CompetitionDetails from "../../features/competition/Details/CompetitionDetails";
import ParticipantPage from "../../features/participant/ParticipantPage";
import TeamDashboard from "../../features/team/TeamDashboard";
import ImageModalContainer from "../common/modals/ImageModalContainer";
import { Garage } from "../../features/staticcontent/Garage";
import { Sponsors } from "../../features/staticcontent/Sponsors";
import { Impress } from "../../features/staticcontent/Impress";
import { Footer } from "../../features/staticcontent/Footer";
import CreatePage from "../../features/competition/Create/CreatePage";
import { RegisterForm } from "../../features/user/RegisterForm";
import { Tournament } from "../../features/staticcontent/Tournament";
import { Event } from "../../features/staticcontent/Event";
import EternalRanking from "../../features/competition/EternalRanking/EternalRanking";
import { SingleLeague } from "../../features/singleleague/SingleLeague";

const App: React.FC<RouteComponentProps> = ({ location }) => {
    const rootStore = useContext(RootStoreContext);
    const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
    const { getUser } = rootStore.userStore;

    useEffect(() => {
        if (token) {            
            getUser().finally(() => setAppLoaded());
        } else {
            setAppLoaded();
        }
    }, [getUser, setAppLoaded, token]);

    if (!appLoaded) return <LoadingComponent content="Lade App..." />;

    return (
        <Fragment>
            <ModalContainer />
            <ImageModalContainer />
            <ToastContainer position="bottom-right" />
            <Fragment>
                <NavBar />
                <Container style={{ marginTop: "7em" }}>
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route
                            exact
                            path="/competitions"
                            component={CompetitionDashboard}
                        />
                        <Route path="/competitions/:id" component={CompetitionDetails} />
                        <Route path="/teams" component={TeamDashboard} />
                        <Route path="/participants/:id" component={ParticipantPage} />
                        <Route path="/garage" component={Garage} />
                        <Route path="/sponsors" component={Sponsors} />
                        <Route path="/impress" component={Impress} />
                        <Route path="/register" component={RegisterForm} />
                        <Route path="/tournament" component={Tournament} />
                        <Route path="/event" component={Event} />
                        <Route path="/singleleague" component={SingleLeague} />
                        <Route path="/eternalranking" component={EternalRanking} />
                        <PrivateRoute path="/createcompetition" component={CreatePage} />
                        {/*<PrivateRoute
                  key={location.key}
                  path={["/createActivity", "/manage/:id"]}
                  component={ActivityForm}
                />
                <PrivateRoute path="/profile/:userName" component={ProfilePage} /> */}
                        <Route component={NotFound} />
                    </Switch>
                </Container>
                <Footer />
            </Fragment>
        </Fragment>
    );
};

export default withRouter(observer(App));
