import React from "react";
import { ICompetitionInfo } from "../../../app/models/competition";
import { Segment, Item, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";

export const CompetitionListItem: React.FC<{
  competitionInfo: ICompetitionInfo;
}> = ({ competitionInfo }) => {
  return (
      <Segment>
        <Item.Group link>
          <Item key={competitionInfo.id} as={Link} to={`/competitions/${competitionInfo.id}`}>
            <Item.Content>
              <Item.Header >
              {/* as={Link} to={`/competitions/${competitionInfo.id}`} */}
                <Icon name="calendar" size="big" />                
                {format(
                  parseISO(competitionInfo.date.toString()), "dd.MM.yyyy"
                )}
              </Item.Header>
              <Item.Description>
                <Icon name="trophy" size="large" style={{ margin: 8 }} />
                {competitionInfo.winnerName}
                <br />
                <Icon
                  name="address book outline"
                  size="large"
                  style={{ margin: 8 }}
                /> {competitionInfo.participantCount}
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
  );
};
