import React, { useContext, useEffect, Fragment, useState } from "react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Header, Form, Button } from "semantic-ui-react";
import { DateInput } from "../../../app/common/form/DateInput";
import { Field, Form as FinalForm } from "react-final-form";
import {
    Competition,
    CompetitionParticipantFormValues,
} from "../../../app/models/competitionForm";
import { FilterType } from "../../../app/models/enums";
import { IRanking } from "../../../app/models/ranking";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { addDays } from "date-fns";
import CompetitionDetailedInfo from "../Details/CompetitionDetailedInfo";
import CompetitionParticipantForm from "./CompetitionParticipantForm";
import { observer } from "mobx-react-lite";
import { ICompetitionParticipant } from "../../../app/models/competition";

const CreatePage = () => {
    const rootStore = useContext(RootStoreContext);
    const { participantList, loadParticipants } = rootStore.participantStore;
    const { competitionCache, createCompetition } = rootStore.competitionStore;
    const { user } = rootStore.userStore;

    const [competition, setCompetition] = useState<Competition | null>(null);
    const [tableKey, setTableKey] = useState(0);

    const [placing, setPlacing] = useState(1);
    const [participantToEdit, setParticipantToEdit] = useState<ICompetitionParticipant | undefined>(undefined);

    useEffect(() => {
        loadParticipants();

        let date = new Date();
        let key = FilterType.Ranking + "_" + date.getFullYear();
        let cachedRanking = competitionCache.get(key);
        if (cachedRanking !== undefined) {
            date = addDays(
                new Date((cachedRanking as IRanking).lastCompetitionDate),
                7
            );
        }

        let comp = new Competition(user!, date);
        setCompetition(comp);
    }, [loadParticipants, competitionCache, user]);

    if (competition === null || participantList === null)
        return <LoadingComponent content="Die Daten werden vorbereitet..." />;

    const handleParticipantSubmit = (
        participantFormValues: CompetitionParticipantFormValues
    ) => {
        console.log('hallo welt');
        if (participantFormValues.placing === placing) {
            let part: ICompetitionParticipant = CompetitionParticipantFormValues.toICompetitionParticipant(participantFormValues);
            competition.participants.push(part);

            setTableKey(competition.participants.length);
            setPlacing(placing + 1);
        }
        else {
            let existingParticipants = competition.participants.filter(cp => cp.participant.id === participantFormValues.participant!.id)
            if (existingParticipants.length > 0)
            {
                var index = competition.participants.indexOf(existingParticipants[0]);
                competition.participants[index]= CompetitionParticipantFormValues.toICompetitionParticipant(participantFormValues);

                var maxPlacing = Math.max.apply(Math, competition.participants.map(p => p.placing));

                setTableKey(Math.random() * 1000);
                setPlacing(maxPlacing + 1);
                setParticipantToEdit(undefined);
            }
        }

        setCompetition(competition);
    };

    const submitCompetition = (comp: Competition) => {
        createCompetition(comp);
    };

    const handleParticipantEdit = (participant: ICompetitionParticipant) => {
        setParticipantToEdit(participant);
    };

    return (
        <Fragment>
            <Header as="h2">Competitionerfassung</Header>

            <FinalForm
                initialValues={competition}
                onSubmit={(values) => {
                    competition.date = values.date;
                    console.log(competition);
                    submitCompetition(competition);
                }}
                render={({ handleSubmit, invalid, pristine }) => (
                    <Form onSubmit={handleSubmit}>
                        <Field
                            name="date"
                            date={true}
                            placeholder="Datum"
                            value={competition!.date}
                            component={DateInput}
                            style={{ width: 150 }}
                        />
                        <Button type="submit">Submit</Button>
                    </Form>
                )}
            />

            <CompetitionDetailedInfo
                key={tableKey}
                participants={competition.participants}
                handleParticipantEdit={handleParticipantEdit}
            />

            <CompetitionParticipantForm
                placing={placing}
                handleParticipantSubmit={handleParticipantSubmit}
                existingParticipant={participantToEdit}
            />
        </Fragment>
    );
};

export default observer(CreatePage);
