export const combineDateAndTime = (date: Date, time: Date) => {
  const timeString = time.getHours() + ":" + time.getMinutes() + ":00";
  const year = date.getFullYear;
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dateString = `${year}-${month}-${day}`;

  return new Date(dateString + " " + timeString);
};


export const getPointsFromPlacing = (placing: number) => {
    switch (placing) {
        case 1:
            return 10;
        case 2:
            return 9;
        case 3:
            return 8;
        case 4:
            return 7;
        case 5:
        case 6:
            return 6;
        case 7:
        case 8:
            return 5;
        case 9:
        case 10:
        case 11:
        case 12:
            return 4;
        case 13:
        case 14:
        case 15:
        case 16:
            return 3;
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
            return 2;
        default:
            return 1;
    }
};