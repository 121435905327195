import React, { useState } from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Checkbox } from "semantic-ui-react";

interface IProps extends FieldRenderProps<boolean>, FormFieldProps {
  falseLabel: string;
}

export const CheckBoxInput: React.FC<IProps> = ({
  input,
  label,
  falseLabel,
  meta: { touched, error },
}) => {
  const [labelText, setLabelText] = useState(input.checked ? label : falseLabel);
  return (
    <Form.Field error={touched && !!error}>
      <Checkbox
        checked={input.checked}
        toggle
        label={labelText}
        onChange={(e, { checked }) => {
          checked ? setLabelText(label) : setLabelText(falseLabel);
          input.onChange(checked);
        }}
      />
    </Form.Field>
  );
};
