import React, { useContext, useEffect, Fragment } from "react";
import { Table, Tab } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

interface IProps {
    year: number;
}

const CompetitionHighlights: React.FC<IProps> = ({ year }) => {
    const rootStore = useContext(RootStoreContext);
    const { highlights, loading, loadHighlights } = rootStore.competitionStore;

    useEffect(() => {
        loadHighlights(year.toString());
    }, [loadHighlights, year]);

    return (
        <Tab.Pane loading={loading} className="highlightTable">
            {highlights && (
                <Table celled unstackable textAlign="center">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Verein</Table.HeaderCell>
                            <Table.HeaderCell>Anzahl</Table.HeaderCell>
                            {highlights.some((p) => p.oneHundredEighties > 0) && year >= 2024 && (
                                <Table.HeaderCell>171+</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.oneHundredEighties > 0) && year < 2024 && (
                                <Table.HeaderCell>180</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg9 > 0) && (
                                <Table.HeaderCell>9</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg10 > 0) && (
                                <Table.HeaderCell>10</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg11 > 0) && (
                                <Table.HeaderCell>11</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg12 > 0) && (
                                <Table.HeaderCell>12</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg13 > 0) && (
                                <Table.HeaderCell>13</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg14 > 0) && (
                                <Table.HeaderCell>14</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg15 > 0) && (
                                <Table.HeaderCell>15</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg16 > 0) && (
                                <Table.HeaderCell>16</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg17 > 0) && (
                                <Table.HeaderCell>17</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg18 > 0) && (
                                <Table.HeaderCell>18</Table.HeaderCell>
                            )}
                            {highlights.some((p) => p.bestleg19 > 0) && (
                                <Table.HeaderCell>19</Table.HeaderCell>
                            )}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {highlights.map((participant) => (
                            <Fragment key={`${participant.participantId}_F`}>
                                <Table.Row key={participant.participantId}>
                                    <Table.Cell rowSpan="2">
                                        <Link to={`/participants/${participant.participantId}`}>
                                            {participant.participantName}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell rowSpan="2">
                                        {participant.participantClub}
                                    </Table.Cell>
                                    <Table.Cell rowSpan="2">
                                        {participant.highlightCount}
                                    </Table.Cell>
                                    {highlights.some((p) => p.oneHundredEighties > 0) && (
                                        <Table.Cell>{participant.oneHundredEighties > 0 ? participant.oneHundredEighties : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg9 > 0) && (
                                        <Table.Cell>{participant.bestleg9 > 0 ? participant.bestleg9 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg10 > 0) && (
                                        <Table.Cell>{participant.bestleg10 > 0 ? participant.bestleg10 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg11 > 0) && (
                                        <Table.Cell>{participant.bestleg11 > 0 ? participant.bestleg11 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg12 > 0) && (
                                        <Table.Cell>{participant.bestleg12 > 0 ? participant.bestleg12 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg13 > 0) && (
                                        <Table.Cell>{participant.bestleg13 > 0 ? participant.bestleg13 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg14 > 0) && (
                                        <Table.Cell>{participant.bestleg14 > 0 ? participant.bestleg14 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg15 > 0) && (
                                        <Table.Cell>{participant.bestleg15 > 0 ? participant.bestleg15 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg16 > 0) && (
                                        <Table.Cell>{participant.bestleg16 > 0 ? participant.bestleg16 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg17 > 0) && (
                                        <Table.Cell>{participant.bestleg17 > 0 ? participant.bestleg17 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg18 > 0) && (
                                        <Table.Cell>{participant.bestleg18 > 0 ? participant.bestleg18 : '-'}</Table.Cell>
                                    )}
                                    {highlights.some((p) => p.bestleg19 > 0) && (
                                        <Table.Cell>{participant.bestleg19 > 0 ? participant.bestleg19 : '-'}</Table.Cell>
                                    )}
                                </Table.Row>
                                <Table.Row key={`${participant.participantId}_HF`} >
                                    <Table.Cell>High-Finishes</Table.Cell>
                                    <Table.Cell colSpan="11" >{participant.highFinishes ?? '-'}</Table.Cell>
                                </Table.Row>
                            </Fragment>
                        ))}
                    </Table.Body>
                </Table>
            )}
        </Tab.Pane>
    );
};

export default observer(CompetitionHighlights);
