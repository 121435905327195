import React, { useContext, useEffect, Fragment } from "react";
import { observer } from "mobx-react-lite";
import {
    TableBody,
    TableCell,
    Table,
    TableRow,
    TableHeader,
    TableHeaderCell,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const EternalRanking = () => {
    const rootStore = useContext(RootStoreContext);
    const {
        loadEternalRanking,
        loading,
        eternalRanking
    } = rootStore.competitionStore;

    useEffect(() => {
        loadEternalRanking();
    }, [loadEternalRanking]);

    if (loading) return <LoadingComponent content="Lade Ewige Rangliste" />;

    return (
        <Fragment>
            <Table style={{ marginBottom: 14 }} unstackable>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Platz</TableHeaderCell>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Punkte</TableHeaderCell>
                        <TableHeaderCell>Teilnahmen</TableHeaderCell>
                        <TableHeaderCell>Durchschnitt</TableHeaderCell>
                        <TableHeaderCell>180s</TableHeaderCell>
                        <TableHeaderCell>Bestlegs</TableHeaderCell>
                        <TableHeaderCell>Highfinishes</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {eternalRanking && eternalRanking.map((c) => (
                        <TableRow>
                            <TableCell collapsing>{eternalRanking.indexOf(c) + 1}</TableCell>
                            <TableCell collapsing>{c.name}</TableCell>
                            <TableCell collapsing>{c.points}</TableCell>
                            <TableCell collapsing>{c.participations}</TableCell>
                            <TableCell collapsing>{(c.points / c.participations).toFixed(2)}</TableCell>
                            <TableCell collapsing>{c.oneEighties}</TableCell>
                            <TableCell collapsing>{c.bestLegs}</TableCell>
                            <TableCell collapsing>{c.highFinishes}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Fragment>
    )
}


export default observer(EternalRanking);