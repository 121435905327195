import React, { useState, useEffect, useContext } from "react";
import {
  IParticipant,
} from "../../../app/models/participant";
import { CompetitionParticipantFormValues } from "../../../app/models/competitionForm";
import { Form, Icon, Dropdown, Button, DropdownProps } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { TextInput } from "../../../app/common/form/TextInput";
import { NumberInput } from "../../../app/common/form/NumberInput";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { NewParticipantForm } from "../../participant/NewParticipantForm";
import { ICompetitionParticipant } from "../../../app/models/competition";

interface IProps {
  placing: number;
  handleParticipantSubmit: (
    participant: CompetitionParticipantFormValues
  ) => void;
  existingParticipant?: ICompetitionParticipant;
}

const CompetitionParticipantForm: React.FC<IProps> = ({
  placing,
  handleParticipantSubmit,
  existingParticipant
}) => {
  const rootStore = useContext(RootStoreContext);
  const { participantList, addNewParticipant } = rootStore.participantStore;
  const { openModal, closeModal } = rootStore.modalStore;

  const [participantFormValues, setParticipant] = useState(
    new CompetitionParticipantFormValues(placing)
  );
  const [options, setOptions] = useState<any[]>([]);
  const [newPartId, setNewPartId] = useState(-1);
  const [selectPart, setSelectPart] = useState<number | undefined>(undefined)

  useEffect(() => {
    let temp: any[] = [];
    participantList!.participants.map((p) =>
      temp.push({ key: p.id, text: p.name, value: p.id })
    );

    setOptions(temp);

    if (existingParticipant !== undefined) {
      setParticipant(CompetitionParticipantFormValues.fromCompetitionParticipant(existingParticipant));
      setSelectPart(existingParticipant.participant.id);
    }
    else {
      setParticipant(new CompetitionParticipantFormValues(placing));
    }
  }, [participantList, placing, existingParticipant]);

  const AddNewParticipant = (newParticipant: IParticipant) => {
    newParticipant.id = newPartId;

    addNewParticipant(newParticipant);

    let newOptions = [...options];
    newOptions.push({ key: newParticipant.id, text: newParticipant.name, value: newParticipant.id })
    setOptions(newOptions);
    setNewPartId(newPartId - 1);

    participantFormValues.participant = newParticipant;
    setSelectPart(newParticipant.id);

    closeModal();
  };

  const onAddParticipant = (event: any, data: DropdownProps) => {
    openModal(<NewParticipantForm handleSubmit={AddNewParticipant} name={data.value ? data.value.toString() : ''} />);
  };

  return (
    <FinalForm
      initialValues={participantFormValues}
      onSubmit={(values: CompetitionParticipantFormValues) => {
        if (values.participant !== null) {
          setSelectPart(-1000);
          handleParticipantSubmit(values);
        }
      }}
      render={({ handleSubmit, form, invalid, pristine }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group >
            <Field
              width={2}
              name="placing"
              component={NumberInput}
              placeholder="Platz"
              value={participantFormValues.placing}
            />
            <Field
              width={5}
              name="name"
              render={() => (
                <Dropdown
                  selection
                  search
                  allowAdditions
                  onChange={(e, data) => {
                    participantFormValues.participant = participantList!.participants.filter(
                      (p) => p.id === data.value
                    )[0];

                    setSelectPart(participantFormValues.participant?.id);
                  }}
                  onAddItem={onAddParticipant}
                  placeholder="Teilnehmer"
                  options={options}
                  value={selectPart}
                />
              )}
            ></Field>
            <Field
              width={3}
              component={TextInput}
              name="oneHundredEighties"
              placeholder="171+"
              value={participantFormValues.oneHundredEighties}
            />
            <Field
              width={2}
              name="bestleg9"
              component={TextInput}
              placeholder="9"
              value={participantFormValues.bestleg9}
            />
            <Field
              width={2}
              name="bestleg10"
              component={TextInput}
              placeholder="10"
              value={participantFormValues.bestleg10}
            />
            <Field
              width={2}
              name="bestleg11"
              component={TextInput}
              placeholder="11"
              value={participantFormValues.bestleg11}
            />
            <Field
              width={2}
              name="bestleg12"
              component={TextInput}
              placeholder="12"
              value={participantFormValues.bestleg12}
            />
            <Field
              width={2}
              name="bestleg13"
              component={TextInput}
              placeholder="13"
              value={participantFormValues.bestleg13}
            />
            <Field
              width={2}
              name="bestleg14"
              component={TextInput}
              placeholder="14"
              value={participantFormValues.bestleg14}
            />
            <Field
              width={2}
              name="bestleg15"
              component={TextInput}
              placeholder="15"
              value={participantFormValues.bestleg15}
            />
            <Field
              width={2}
              name="bestleg16"
              component={TextInput}
              placeholder="16"
              value={participantFormValues.bestleg16}
            />
            <Field
              width={2}
              name="bestleg17"
              component={TextInput}
              placeholder="17"
              value={participantFormValues.bestleg17}
            />
            <Field
              width={2}
              name="bestleg18"
              component={TextInput}
              placeholder="18"
              value={participantFormValues.bestleg18}
            />
            <Field
              width={2}
              name="bestleg19"
              component={TextInput}
              placeholder="19"
              value={participantFormValues.bestleg19}
            />

            <Field
              width={5}
              name="highFinishes"
              component={TextInput}
              placeholder="Highfinishes"
              value={participantFormValues.highFinishes}
            />
          </Form.Group>
          <Button positive type="submit" width={2}>
            {existingParticipant !== undefined ?
              (<Icon name="save" />) :
              (<Icon name="plus" />)}
          </Button>
        </Form>
      )}
    />
  );
};

export default observer(CompetitionParticipantForm);
