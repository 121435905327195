import React from "react";
import { Tab, Segment, Header } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import TeamDetails from "./TeamDetails";

const TeamDashboard = () => {
    const panes = [
        {
            menuItem: "Finnigan's Harp 1",
            render: () => <TeamDetails id={1} />,
        },
        {
            menuItem: "Finnigan's Harp 2",
            render: () => <TeamDetails id={2} />,
        },
        {
            menuItem: "Finnigan's Harp 3",
            render: () => <TeamDetails id={3} />,
        },
        {
            menuItem: "Finnigan's Harp 4",
            render: () => <TeamDetails id={4} />,
        },
        {
            menuItem: "Finnigan's Harp 5",
            render: () => <TeamDetails id={5} />,
        },
    ];

    return (
        <Segment>
            <Header as="h2" className="homeHeader">Unsere Mannschaften</Header>
            <Tab menu={{ pointing: true, stackable: true }} panes={panes} />
        </Segment>
    );
};

export default observer(TeamDashboard);