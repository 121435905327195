import React, { useContext, useEffect, Fragment } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { Tab, Card } from "semantic-ui-react";
import NotFound from "../../app/layout/NotFound";
import TeamMemberListItem from "./TeamMemberListItem";
import { observer } from "mobx-react-lite";
import { TeamHeader } from "./TeamHeader";

const TeamDetails: React.FC<{ id: number }> = ({ id }) => {
  const context = useContext(RootStoreContext);
  const { getTeam, loading, team } = context.teamStore;

  useEffect(() => {
    getTeam(id);
  }, [getTeam, id]);
  return (
    <Tab.Pane loading={loading}>
      {team ? (
        <Fragment>
          <TeamHeader team={team} />
          <Card.Group itemsPerRow={4}>
            {team.teamMember.map((tm) => (
              <TeamMemberListItem key={tm.id} teamMember={tm} />
            ))}
          </Card.Group>
        </Fragment>
      ) : (
        <NotFound />
      )}
    </Tab.Pane>
  );
};

export default observer(TeamDetails);
