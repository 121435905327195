import React from "react";
import { IHighlights } from "../../app/models/participant";
import { Statistic, Item, Icon, Grid, Tab } from "semantic-ui-react";

interface IProps {
    highlights: IHighlights;
}

export const ParticipantHighlights: React.FC<IProps> = ({ highlights }) => {
    return (
        <Tab.Pane>
            <Item>
                <Item.Content>
                    <Item.Header as="h2">Highlights {highlights.year}</Item.Header>
                    <Item.Extra>
                        <Statistic.Group widths={12}>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.oneHundredEighties > 0
                                        ? highlights.oneHundredEighties
                                        : "-"}
                                </Statistic.Value>
                                <Statistic.Label>{highlights.year >= 2024 ? "171+" : "180"}</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg9 > 0 ? highlights.bestleg9 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL9</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg10 > 0 ? highlights.bestleg10 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL10</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg11 > 0 ? highlights.bestleg11 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL11</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg12 > 0 ? highlights.bestleg12 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL12</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg13 > 0 ? highlights.bestleg13 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL13</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg14 > 0 ? highlights.bestleg14 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL14</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg15 > 0 ? highlights.bestleg15 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL15</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg16 > 0 ? highlights.bestleg16 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL16</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg17 > 0 ? highlights.bestleg17 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL17</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg18 > 0 ? highlights.bestleg18 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL18</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>
                                    {highlights.bestleg19 > 0 ? highlights.bestleg19 : "-"}
                                </Statistic.Value>
                                <Statistic.Label>BL19</Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                        {highlights.highFinishes !== null &&
                        <Grid      
                            columns='equal'
                            style={{
                                fontSize: "3rem",
                                marginTop: "30px",
                                marginBottom: "10px",
                            }}
                        >
                            <Grid.Column
                                width={1}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Icon
                                    name="chart line"
                                    style={{ marginTop: "20px", marginLeft: "20px" }}
                                    className="finIcon"
                                />
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <p>{highlights.highFinishes}</p>
                            </Grid.Column>
                        </Grid>}
                    </Item.Extra>
                </Item.Content>
            </Item>
        </Tab.Pane>
    );
};
