import React, { useContext, useEffect } from "react";
import { List, Tab } from "semantic-ui-react";
import { CompetitionListItem } from "./CompetitionListItem";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";

interface IProps {
  year: number;
}

const CompetitionList: React.FC<IProps> = ({ year }) => {
  const rootStore = useContext(RootStoreContext);
  const { competitions, loadCompetitions, loading, setPredicate } = rootStore.competitionStore;

  useEffect(() => {
    setPredicate('year', year.toString())
    loadCompetitions();
  }, [loadCompetitions, setPredicate, year]);

  return (
    <Tab.Pane loading={loading}>
      <List divided relaxed>
        {competitions && competitions.map((c) => (
          <CompetitionListItem key={c.id} competitionInfo={c} />
        ))}
      </List>
    </Tab.Pane>
  );
};

export default observer(CompetitionList);