import React from "react";
import { ICompetitionSummary } from "../../app/models/participant";
import { Card, Item, Statistic, Tab } from "semantic-ui-react";
import { format, parseISO, getDayOfYear } from "date-fns";

interface IProps {
    competitions: ICompetitionSummary;
}

export const ParticipantCompetitions: React.FC<IProps> = ({ competitions }) => {
    return (
        <Tab.Pane>
            <Item>
                <Item.Content>
                    <Item.Header as="h2">Teilnahmen {competitions.year}</Item.Header>
                    <Item.Extra>
                        <Card.Group itemsPerRow={5}>
                            {competitions.competitions.map((c) => (
                                <Card key={getDayOfYear(parseISO(c.date.toString()))} style={{ width: '135px' }}>
                                    <Card.Content>
                                        <Card.Header
                                            content={format(
                                                parseISO(c.date.toString()),
                                                "dd.MM.yyyy"
                                            )}
                                        />
                                    </Card.Content>
                                    <Card.Content>
                                        <Statistic.Group size="mini" horizontal>
                                            <Statistic label="Platz" value={c.placing} />
                                            <Statistic
                                                label="Punkte"
                                                value={c.points}
                                                style={{ marginBottom: 14 }}
                                            />
                                            <Statistic
                                                label="Highlights"
                                                value={c.numberOfHighlights}
                                                style={{ marginBottom: 14 }}
                                            />
                                        </Statistic.Group>
                                    </Card.Content>
                                </Card>
                            ))}
                        </Card.Group>
                    </Item.Extra>
                </Item.Content>
            </Item>
        </Tab.Pane>
    );
};
